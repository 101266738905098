import {H4, Small, Title1} from '../../typography';
import {useContext, useEffect, useMemo} from 'react';
import {ModalContext} from '../../global/ModalProvider';
import {BackgroundImage, Context, OverlayModal} from '../../global';
import {Column, Container, Row} from '../../layout';
import {Alert, Button} from '../../forms';
import {captureException} from '@sentry/nextjs';
import {useForm} from '../../../hooks/useForm';
import {InlineForm, Input} from '../InlineActionForm/InlineActionForm.styles';
import {emailSubscribe} from '../../../api/functions/emailSubscribe';
import {updateUser} from '../../../api';
import {omitFalsey, unNull} from '../../../utils';
import {useTrack} from '../../global/SegmentProvider';
import {useNewNavigation} from '../../../hooks/useNewNavigation';

export const AttentionModal: React.FC = () => {
  const {activeProps, closeModal} = useContext(ModalContext);
  const props = useMemo(() => activeProps.attention, [activeProps.attention]);
  const {session} = useContext(Context);
  const {goTo} = useNewNavigation();
  const track = useTrack();

  const {data, handleChange, handleSubmit, loading, errors, setValue} =
    useForm<{
      email: string;
    }>({
      initialValues: {
        email: session?.email || '',
      },
      onSubmit: async (e, {data, setErrors}) => {
        try {
          await track('Attention modal submitted', {
            cohort: props?.cohort,
            title: props?.title,
          });
          closeModal?.({name: 'attention'});

          if (session) {
            await updateUser({
              details: {
                cohorts: omitFalsey([props?.cohort]),
              },
              session: unNull(session),
            });
          } else {
            await emailSubscribe({
              email: data?.email,
              cohorts: omitFalsey([props?.cohort]),
            });
          }
          if (props.link) {
            goTo(props.link);
          }
        } catch (err) {
          captureException(err);
          await track('Attention modal failed', {
            cohort: props?.cohort,
            title: props?.title,
          });
          setErrors({
            form: 'An unexpected error occured.',
          });
        }
      },
      validations: {
        email: {
          required: {
            value: true,
            message: 'Please enter your email address',
          },
        },
      },
    });

  useEffect(() => {
    if (session?.email) {
      setValue('email', session?.email);
    }
  }, [session?.email, setValue]);

  return (
    <OverlayModal name="attention" closeButton>
      <Container maxWidth="md">
        <Row spacing={40}>
          <Column textAlign="center">
            <BackgroundImage image={props?.backgroundImage} />
            <Title1 as="h2" spacing={10}>
              {props?.title}
            </Title1>
            <H4>{props?.description}</H4>
          </Column>
        </Row>
        <Row as="form" onSubmit={handleSubmit}>
          <Alert error={errors?.form} />
          <Column>
            <InlineForm>
              <Input
                type="email"
                placeholder="Enter your email address"
                value={data?.email}
                onChange={handleChange('email')}
              />

              <Button
                id="AttentionSubmit"
                type="submit"
                size="md"
                loading={loading}
              >
                {loading ? 'Loading' : props?.buttonText || 'Submit'}
              </Button>
            </InlineForm>
            {errors?.email ? (
              <Small colour="accent">{errors?.email}</Small>
            ) : null}
          </Column>
        </Row>
      </Container>
    </OverlayModal>
  );
};
