import {MUTATE_EMAIL_CAPTURE} from '../../api/mutations';
import {
  EmailSubscribeMutation,
  EmailSubscribeMutationVariables,
} from '../../types/api';
import {getGraphClient} from '../../utils/graph';

export const emailSubscribe = async (
    variables: EmailSubscribeMutationVariables,
) => {
  const {data} = await getGraphClient().mutate<EmailSubscribeMutation>({
    mutation: MUTATE_EMAIL_CAPTURE,
    variables,
  });
  return data?.register;
};
